<template>
  <!--begin::Card Body-->
  <div class="card-body p-10">
    <div class="row align-items-center desktop">
      <div class="col-12">
        <div class="formacion row">
          <div class="col-1">
            <div class="textoformacion">FORMACIÓN</div>
          </div>
          <div class="col-11 pl-2 padimagen">
            <img
              src="../../public/media/illustrations/portada.webp"
              alt="Mujer joven con cabello rizado dando una presentación en un aula moderna con paredes de ladrillo visto. Sostiene un control remoto y sonríe a los asistentes. El público, compuesto por varias mujeres, la escucha atentamente. Al fondo, una pantalla grande refleja la silueta de los participantes y hay varias plantas decorativas junto a una ventana."
              class="img-fluid img-portada card"
            />
          </div>
        </div>
      </div>
      <div class="espacio"></div>
      <div class="mt-5 fs-1">{{ $t("cursosFormacionMujeres") }}</div>
      <div class="mt-2 fs-4">
        <p>
          Desde el Instituto Aragonés de la Mujer, te ofrecemos
          <strong>15 cursos</strong> destinados a promover la igualdad de género
          en empresas y organizaciones, el desarrollo profesional y digital de
          mujeres emprendedoras y en búsqueda o mejora de empleo, así como la
          formación de las y los profesionales que trabajan en la prevención e
          intervención de la violencia contra las mujeres.
        </p>
        <p>
          En nuestra plataforma www.iamformacion.es, encontrarás la relación de
          acciones formativas diseñadas en <strong>5 áreas</strong>:
        </p>
        <ul>
          <li><strong>VIOLENCIAS CONTRA LA MUJER</strong></li>
          <li><strong>IGUALDAD EN LA EMPRESA</strong></li>
          <li><strong>DIGITAL</strong></li>
          <li><strong>EMPRENDIMIENTO</strong></li>
          <li><strong>ORIENTACIÓN Y EMPLEO</strong></li>
        </ul>
        <p>
          Todas las acciones son gratuitas, se realizan en modalidad síncrona
          (videoconferencia), con una duración de <strong>2 horas</strong>.
        </p>
        <p>
          Los cursos se dirigen a diferentes perfiles, en función del objetivo
          de cada acción y concretándose en la descripción de cada una de ellos.
          Cada acción formativa tiene un límite máximo de 50 plazas.
        </p>
        <p>
          Las inscripciones se realizan exclusivamente a través del portal web:
          www.iamformacion.es y estarán disponibles desde el día 7 de marzo.
        </p>
        <p>
          Además, quienes participan en los cursos pueden reforzar su formación
          con <strong>consultas personalizadas</strong> a través de las
          Asesorías Empresarial y Laboral del IAM. Nuestro objetivo es
          proporcionarte herramientas prácticas para tu crecimiento personal y
          profesional o las claves y pautas para la implantación de
          procedimientos que han de cumplir las empresas y organizaciones en
          materia de igualdad.
        </p>
        <p>
          Si tienes dudas, puedes consultar en la plataforma en “Preguntas
          frecuentes”, a través del teléfono 976 716 720 o mediante el correo:
          <a href="mailto:iamformacion@aragon.es">iamformacion@aragon.es</a>
        </p>
        <p>
          <a href="/docs/ProgramaPlandeFormacion2025.pdf" target="blank"
            ><i class="fa-regular fa-file-pdf fa-xl colorAzul me-2"></i>Programa
            Plan de Formación 2025.pdf</a
          >
        </p>
      </div>
    </div>
  </div>
  <!--begin::Card Body-->
</template>

// Añadimos estilos inline
<style scoped>
/* div de la seccion azul de formacion */
.formacion {
  background-color: #00369a;
  width: 95%;
  height: 390px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  position: absolute;
  margin-left: 5%;
  padding: 25px;
}

/* img de portada, dentro del div de formacion */
.img-portada {
  width: 70%;
  height: 340px;
  object-fit: cover;

  /* en movil no se muestra */
  @media (max-width: 768px) {
    display: none;
  }
}

.padimagen {
  @media (max-width: 1000px) {
    padding-left: 25px;
  }
}

/* da cuerpo al espacio que ocupa el div azul de formacion */
.espacio {
  width: 80%;
  height: 390px;
}

/* formato del texto invertido vertical y fuente */
.textoformacion {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  transform-origin: center center;
  font-size: 59px;
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.colorAzul {
  color: #00369a;
}
</style>
