
import CondicionesPrivacidadModal from "@/components/modals/CondicionesPrivacidad.vue";
import localidades from "@/core/data/localidades";
import provincias from "@/core/data/provinciasEP";
import CursosModule from "@/store/modules/CursosModule";
import InscripcionModule from "@/store/modules/InscripcionModule";
import Contacto from "@/views/ContactoContent.vue";
import FAQs from "@/views/FAQsContent.vue";
import Intro from "@/views/IntroContent.vue";
import { ElMessage, ElMessageBox } from "element-plus";
import "moment/locale/es";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ErrorMessage, useField, useForm } from "vee-validate";
import { computed, defineComponent, onBeforeMount, ref, watch } from "vue";
import { useI18n } from "vue-i18n/index";
import { useRoute, useRouter } from "vue-router";
import { Vue3Lottie } from "vue3-lottie";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";
import * as Yup from "yup";

interface InscripcionCreate {
  nombre: string;
  apellidos: string;
  dni: string;
  telefono: string;
  email: string;
  provincia: string;
  localidad: string;
  codigoPostal: string;
  perfil: string;
  nombreEmpresa?: string | null;
  localidadEmpresa?: string | null;
  cifEmpresa?: string | null;
  sectorEmpresa?: string | null;
  empleadosEmpresa?: string | null;
  entidad?: string | null;
  puesto?: string | null;
  modalidad: string;
  politicaPrivacidad: boolean;
}
interface Localidad {
  label: string;
  value: string;
}

export const useUsername = ({ user }) => {
  return { username: user.name };
};

export default defineComponent({
  name: "kt-landing",
  components: {
    Intro,
    FAQs,
    Contacto,
    Vue3Lottie,
    CondicionesPrivacidadModal,
    ErrorMessage,
  },

  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const token = route.query.act;
    const router = useRouter();

    const store = useStore();
    const module = getModule(CursosModule, store);
    const moduleInscripciones = getModule(InscripcionModule, store);

    const loadingCursos = ref(false);
    const errorCargandoCursos = ref(false);
    const crearInscripcion = ref(false);
    const perfilesAllowed = ref<string[]>([]);

    const profesionalAllowedValues = ["Violencias contra la mujer"];
    const particularAllowedValues = [
      "Digital",
      "Emprendimiento",
      "Orientación y empleo",
    ];
    const empresaAllowedValues = [
      "Igualdad en la empresa",
      "Digital",
      "Emprendimiento",
    ];

    onBeforeMount(() => {
      if (token) {
        loadingCursos.value = true;
        module.fetchById({ formularioId: token as string }).then(function () {
          if (module.errors) {
            errorCargandoCursos.value = true;
          }
          loadingCursos.value = false;
        });
      } else {
        errorCargandoCursos.value = false;
        loadingCursos.value = true;
        module.fetch().then(function () {
          if (module.errors) {
            errorCargandoCursos.value = true;
          }
          loadingCursos.value = false;
        });
      }
    });
    const datosCursos = computed(() => {
      const datos = module.cursos.map((c) => {
        const badges: string[] = [];
        if (profesionalAllowedValues.includes(c.areaTematica)) {
          badges.push("PROFESIONAL");
        }
        if (particularAllowedValues.includes(c.areaTematica)) {
          badges.push("PARTICULAR");
        }
        if (empresaAllowedValues.includes(c.areaTematica)) {
          badges.push("EMPRESA");
        }

        return { ...c, badges };
      });

      return datos;
    });
    const datosCurso = computed(() => {
      const badges: string[] = [];
      const curso = module.curso;

      if (profesionalAllowedValues.includes(curso?.areaTematica as string)) {
        badges.push("PROFESIONAL");
      }
      if (particularAllowedValues.includes(curso?.areaTematica as string)) {
        badges.push("PARTICULAR");
      }
      if (empresaAllowedValues.includes(curso?.areaTematica as string)) {
        badges.push("EMPRESA");
      }
      if (curso) {
        curso.badges = badges;
      }

      return curso;
    });

    watch(
      () => route.fullPath,
      (newFullPath) => {
        const formularioId = newFullPath.match(/act=([^&]*)/)?.[1];
        if (!formularioId) {
          module.setDetailData(null);
          module.fetch();
        }
      }
    );

    watch(datosCurso, () => {
      if (!datosCurso.value) return;

      perfilesAllowed.value = [];

      if (
        profesionalAllowedValues.includes(
          datosCurso.value.areaTematica as string
        )
      ) {
        perfilesAllowed.value.push("PROFESIONAL");
      }
      if (
        particularAllowedValues.includes(
          datosCurso.value.areaTematica as string
        )
      ) {
        perfilesAllowed.value.push("PARTICULAR");
      }
      if (
        empresaAllowedValues.includes(datosCurso.value.areaTematica as string)
      ) {
        perfilesAllowed.value.push("EMPRESA");
      }

      resetForm({
        values: {
          nombre: "",
          apellidos: "",
          dni: "",
          telefono: "",
          email: "",
          provincia: "",
          localidad: "",
          codigoPostal: "",
          perfil: perfilesAllowed.value[0] ?? "",
          nombreEmpresa: "",
          localidadEmpresa: "",
          cifEmpresa: "",
          sectorEmpresa: "",
          empleadosEmpresa: "",
          entidad: "",
          puesto: "",
          modalidad: "VIDEOCONFERENCIA",
          politicaPrivacidad: false,
        },
      });
    });

    const isValidNIForNIE = (dni) => {
      const nifRegex = /^[0-9]{8}[A-Z]$/;
      const nieRegex = /^[XYZ][0-9]{7}[A-Z]$/;
      const validChars = "TRWAGMYFPDXBNJZSQVHLCKET";

      if (nifRegex.test(dni)) {
        const number = parseInt(dni.substring(0, 8), 10);
        const letter = validChars.charAt(number % 23);
        return letter === dni.charAt(8);
      }

      if (nieRegex.test(dni)) {
        // Cambiar la X, Y o Z a su equivalente numérico
        let number = dni.substring(1);
        switch (dni.charAt(0)) {
          case "X":
            number = "0" + number;
            break;
          case "Y":
            number = "1" + number;
            break;
          case "Z":
            number = "2" + number;
            break;
        }
        number = parseInt(number, 10);
        const letter = validChars.charAt(number % 23);
        return letter === dni.charAt(8);
      }
      return false;
    };

    const isValidCIF = (cif) => {
      const cifRegex = /^[ABCDEFGHJKLMNPQRSUVW]\d{7}[0-9A-J]$/;

      if (!cifRegex.test(cif)) {
        return false;
      }

      const controlLetters = "JABCDEFGHI"; // Letras de control cuando no es numérico
      const digits = cif.substring(1, 8);
      const controlChar = cif.charAt(8);
      let sumEven = 0;
      let sumOdd = 0;

      // Algoritmo de validación del CIF
      for (let i = 0; i < 7; i++) {
        let num = parseInt(digits[i], 10);
        if (i % 2 === 0) {
          // Posiciones impares (pares en índice 0-based)
          let double = num * 2;
          sumOdd += double > 9 ? double - 9 : double;
        } else {
          // Posiciones pares (impares en índice 0-based)
          sumEven += num;
        }
      }

      const totalSum = sumOdd + sumEven;
      const controlDigit = (10 - (totalSum % 10)) % 10;

      // Verificación del dígito de control
      if (/[ABCDEFGHJNPQRSUVW]/.test(cif[0])) {
        // Empresas que pueden tener dígito o letra
        return (
          controlChar == controlDigit.toString() ||
          controlChar == controlLetters[controlDigit]
        );
      } else {
        // Empresas que solo pueden tener un dígito numérico
        return controlChar == controlDigit.toString();
      }
    };

    const schema = Yup.object({
      nombre: Yup.string().required("Nombre es obligatorio"),
      apellidos: Yup.string().required("Apellidos es obligatorio"),
      dni: Yup.string()
        .required("DNI es obligatorio")
        .test("is-valid-dni", "Documento no válido", (value) =>
          isValidNIForNIE(value)
        ),
      email: Yup.string()
        .email("Debe ser un correo electrónico válido")
        .required("Email es obligatorio"),
      telefono: Yup.string().required("Teléfono es obligatorio"),
      localidad: Yup.string().required("Localidad es obligatorio"),
      provincia: Yup.string().required("Provincia es obligatorio"),
      codigoPostal: Yup.string().required("Código postal es obligatorio"),
      modalidad: Yup.string().required("Modalidad es obligatoria"),
      perfil: Yup.string().required(),
      politicaPrivacidad: Yup.boolean()
        .required()
        .isTrue("Es obligatorio aceptar la política de privacidad"),
      nombreEmpresa: Yup.string()
        .ensure()
        .when("perfil", {
          is: "EMPRESA",
          then: Yup.string().required("Nombre de empresa es obligatorio"),
        }),
      localidadEmpresa: Yup.string()
        .ensure()
        .when("perfil", {
          is: "EMPRESA",
          then: Yup.string().required("Localidad de la empresa es obligatorio"),
        }),
      cifEmpresa: Yup.string()
        .ensure()
        .when("perfil", {
          is: "EMPRESA",
          then: Yup.string()
            .required("CIF de la empresa es obligatorio")
            .test("is-valid-dni", "Documento no válido", (value) =>
              isValidCIF(value)
            ),
        }),
      sectorEmpresa: Yup.string()
        .ensure()
        .when("perfil", {
          is: "EMPRESA",
          then: Yup.string().required("Sector de la empresa es obligatorio"),
        }),
      empleadosEmpresa: Yup.string()
        .ensure()
        .when("perfil", {
          is: "EMPRESA",
          then: Yup.string().required(
            "Número de empleados de la empresa es obligatorio"
          ),
        }),
      entidad: Yup.string()
        .ensure()
        .when("perfil", {
          is: "PROFESIONAL",
          then: Yup.string().required().label("Entidad es obligatorio"),
        }),
      puesto: Yup.string()
        .ensure()
        .when("perfil", {
          is: "PROFESIONAL",
          then: Yup.string()
            .required()
            .label("Puesto desempeñado es obligatorio"),
        }),
    });

    const { resetForm, handleSubmit, errors } = useForm<InscripcionCreate>({
      validationSchema: schema,
    });

    const { value: nombre } = useField("nombre");
    const { value: apellidos } = useField("apellidos");
    const { value: dni } = useField("dni");
    const { value: telefono } = useField("telefono");
    const { value: email } = useField("email");
    const { value: provincia } = useField("provincia");
    const { value: localidad } = useField("localidad");
    const { value: codigoPostal } = useField<number>("codigoPostal");
    const { value: perfil } = useField("perfil");
    const { value: nombreEmpresa } = useField("nombreEmpresa");
    const { value: localidadEmpresa } = useField("localidadEmpresa");
    const { value: cifEmpresa } = useField("cifEmpresa");
    const { value: sectorEmpresa } = useField("sectorEmpresa");
    const { value: empleadosEmpresa } = useField("empleadosEmpresa");
    const { value: entidad } = useField("entidad");
    const { value: puesto } = useField("puesto");
    const { value: modalidad } = useField("modalidad");
    const { value: politicaPrivacidad } = useField("politicaPrivacidad");

    const i18n = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    let localidadesTodas = ref<Localidad[]>([]);
    localidadesTodas.value = localidades
      .map((l) => {
        return { label: l.localidad, value: l.localidad };
      })
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });

    let localidadesFiltradas = ref<Localidad[]>([]);
    localidadesFiltradas.value = localidades
      .filter((loc) => loc.provincia === (provincia.value as string))
      .map((l) => {
        return { label: l.localidad, value: l.localidad };
      })
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });

    const updateLocalidades = () => {
      localidad.value = "";
      return (localidadesFiltradas.value = localidades
        .filter((loc) => loc.provincia === provincia.value)
        .map((l) => {
          return { label: l.localidad, value: l.localidad };
        })
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        }));
    };
    return {
      debug: 0,
      i18n,
      errors,
      token,
      errorCargandoCursos,
      loadingCursos,
      datosCurso,
      datosCursos,
      nombre,
      apellidos,
      dni,
      telefono,
      email,
      provincia,
      localidad,
      codigoPostal,
      modalidad,
      perfil,
      nombreEmpresa,
      localidadEmpresa,
      cifEmpresa,
      sectorEmpresa,
      empleadosEmpresa,
      entidad,
      puesto,
      politicaPrivacidad,
      provincias,
      localidades,
      localidadesFiltradas,
      localidadesTodas,
      crearInscripcion,
      updateLocalidades,
      guardar: handleSubmit(async (values) => {
        ElMessageBox.confirm(
          t("formularios.confirmacionEnvio.texto"),
          t("formularios.confirmacionEnvio.titulo"),
          {
            confirmButtonText: t("formularios.confirmacionEnvio.aceptar"),
            cancelButtonText: t("formularios.confirmacionEnvio.revisar"),
            type: "info",
          }
        )
          .then(async () => {
            const cursoId = datosCurso.value?.id as string;

            if (!cursoId) return;

            let videoconferencia = false;
            let presencial = false;

            if (
              datosCurso.value?.videoconferencia &&
              datosCurso.value?.presencial
            ) {
              if (values.modalidad === "VIDEOCONFERENCIA") {
                videoconferencia = true;
              } else if (values.modalidad === "PRESENCIAL") {
                presencial = true;
              }
            } else if (datosCurso.value?.videoconferencia) {
              videoconferencia = true;
            } else if (datosCurso.value?.presencial) {
              presencial = true;
            }

            crearInscripcion.value = true;

            try {
              await moduleInscripciones
                .crear({
                  inscripcion: {
                    nombre: values.nombre,
                    apellidos: values.apellidos,
                    dni: values.dni,
                    email: values.email,
                    telefono: values.telefono,
                    localidad: values.localidad,
                    provincia: values.provincia,
                    codigoPostal: parseInt(
                      values.codigoPostal as unknown as string
                    ),
                    perfil: values.perfil,
                    nombreEmpresa: values.nombreEmpresa ?? "",
                    localidadEmpresa: values.localidadEmpresa ?? "",
                    cifEmpresa: values.cifEmpresa ?? "",
                    sectorEmpresa: values.sectorEmpresa ?? "",
                    empleadosEmpresa: values.empleadosEmpresa ?? "",
                    entidad: values.entidad ?? "",
                    puesto: values.puesto ?? "",
                    videoconferencia: videoconferencia,
                    presencial: presencial,
                    estado: "INSCRITO",
                    cursoId: cursoId,
                  },
                })
                .then(() => {
                  Swal.fire({
                    text: t("formularios.inscripcionCursoDetalle.crear.exito"),
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText: t("dialogs.okButton"),
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  }).then(() => {
                    module.fetch();
                    router.push("/home");
                  });
                });
            } catch (err) {
              Swal.fire({
                text: t("formularios.inscripcionCursoDetalle.crear.error"),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: t("dialogs.okButton"),
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            } finally {
              crearInscripcion.value = false;
            }
          })
          .catch(() => {
            ElMessage({
              type: "info",
              message: t("formularios.confirmacionEnvio.revisar"),
            });
          });
      }),
      onItemTap: async (curso) => {
        module.setDetailData(curso);
        window.location.href =
          window.location.origin + "/#/home?act=" + curso.formularioId;
      },
      perfilesAllowed,
    };
  },
});
